import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  amscompanyAccessPointList: [],
  amscompanyAccessGroupList: [],
  amssynccompanyAccessGroupList: [],
  amsVendorSyncAccessUsersList: []
};

const amsSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    amscompanyAccessPointAction: (state, action) => {
      state.amscompanyAccessPointList = [
        ...state.amscompanyAccessPointList,
        ...action.payload.amscompanyAccessPointList,
      ];
    },
    amscompanyAccessGroupAction: (state, action) => {
      state.amscompanyAccessGroupList = [
        ...state.amscompanyAccessGroupList,
        ...action.payload.amscompanyAccessGroupList,
      ];
    },
    amssynccompanyAccessGroupAction: (state, action) => {
      state.amssynccompanyAccessGroupList = action.payload.amssynccompanyAccessGroupList;
    },
    amsVendorSyncAccessUsersAction: (state, action) => {
      state.amsVendorSyncAccessUsersList = action.payload.amsVendorSyncAccessUsersList;
    },
  },
});

export const { amscompanyAccessPointAction, amscompanyAccessGroupAction, amssynccompanyAccessGroupAction, amsVendorSyncAccessUsersAction} =
  amsSlice.actions;

export default amsSlice.reducer;
