import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginUserName: localStorage.getItem("loginUserName") ?? "",
  loginUserPersonId: localStorage.getItem("loginUserPersonId") ?? "",
  loginUserEmail: localStorage.getItem("loginUserEmail") ?? "",
};

const userManagerSlice = createSlice({
  name: "userManager",
  initialState,
  reducers: {
    updateUserDetailAction: (state, action) => {
      state.loginUserName = action.payload.name;
      state.loginUserPersonId = action.payload.personId;
      state.loginUserEmail = action.payload.email;
      localStorage.setItem("loginUserName", action.payload.name);
      localStorage.setItem("loginUserPersonId", action.payload.personId);
      localStorage.setItem("loginUserEmail", action.payload.email);
    },
    userRoleListAction: (state, action) => {
      state.userRoleList = action.payload.userRoleList
      ;
    }
  },
});

export const { updateUserDetailAction, userRoleListAction } = userManagerSlice.actions;
export default userManagerSlice.reducer;
