import { Provider, useDispatch } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import { useMsalAuthentication, useIsAuthenticated } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useLazyGetUserInfoQuery, useLazyGetAllUserRolesQuery } from "app/redux/UserManager/userManagerApi";
import {userRoleListAction} from "app/redux/UserManager/userManagerSlice";

// ALL PAGE ROUTES
import routes from "./routes";
// REDUX STORE
import { store } from "./redux/Store";
// FAKE DB
// import "../fake-db";

function App() {
  useMsalAuthentication(InteractionType.Redirect);
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const [getuserInfo] = useLazyGetUserInfoQuery();
  const [getAllUserRoles] = useLazyGetAllUserRolesQuery();
  const [IsRoleFetch, setIsRoleFetch] = useState(false);
  useEffect(() => {
    if (isAuthenticated) {
      async function fetchMyAPI() {
        await getuserInfo();
        await getAllUserRoles();
        getAllUserRoles().then(
          (result) => {
            setIsRoleFetch(true);
            if(result.isSuccess){
              if (result.data && result.data.length > 0) {
                let resultData = result.data;
                dispatch(
                  userRoleListAction({
                    userRoleList: resultData,
                  })
                );
              }
            }
            else{

            }
          });
        }
      
      fetchMyAPI();
    }
  }, [isAuthenticated]);

  return (
    <Fragment>
      {
        IsRoleFetch ? 
        <Provider store={store}>
          <RouterProvider router={routes} />
          <ToastContainer />
        </Provider> : ""
      }
    </Fragment>
    
    
  );
}

export default App;
